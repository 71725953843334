import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners'; // Importando o componente de animação
import '../App.css';

function App() {
  const [editions, setEditions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar se o menu está aberto ou fechado

  useEffect(() => {
    const fetchEditions = async () => {
      try {
        const response = await axios.get(`https://scoreboard-j6oz.onrender.com/editions/findAll/${page}`);
        setEditions(prevEditions => [...prevEditions, ...response.data]); // Concatena os novos dados com os existentes
        setLoading(false);
      } catch (error) {
        console.error('Error fetching editions:', error);
        setLoading(false); // Em caso de erro, definir o estado de carregamento como falso para parar o indicador de carregamento
      }
    };

    if (loading) {
      fetchEditions();
    }
  }, [loading, page]);

useEffect(() => {
  const handleScroll = () => {
    const windowHeight = window.innerHeight; // Altura da janela visível
    const documentHeight = document.documentElement.scrollHeight; // Altura total do documento
    const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0); // Posição atual do scroll

    if (documentHeight - windowHeight - scrollPosition < 100) {
      // Quando o usuário chega perto do final da página, carrega mais dados
      setPage(prevPage => prevPage + 1); // Incrementa a página atual
      setLoading(true); // Define loading como true para carregar mais dados
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Alternar entre aberto e fechado
  };

  return (
    <div>
      <nav className="navbar navbar-dark bg-primary">
        <div className="container">
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="#">
            <i className="fa d-inline fa-lg fa-circle-o"></i>
            <b>Rhymes Score</b>
          </a>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/topbigfour">Top 5 Big Four - São Paulo SP - 2024</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">Sobre Nós</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h6 className="text-body">Ultimas Edições</h6>
            </div>
          </div>
        </div>
      </div>
      {editions.map((edition, index) => (
        <div className="py-3" key={index}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card bg-light p-3">
                  <div className="row mb-4">
                    <div className="col-3 d-flex flex-column align-items-center">
                      <ul style={{ listStyleType: 'none', padding: 0 }}> {/* Estilo para remover bullet e padding */}
                        {edition.mcs.map((mc, mcIndex) => (
                          <li key={mcIndex} className="text-center">
                            <Link to={`/profile/${mc.nickName}`}>
                              <img className="img-fluid d-block mx-auto rounded-circle" src={mc.photo} width="100" alt="Card image cap" />
                            </Link>
                            <p className="mb-0">{mc.nickName}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-9">
                      <h4><b>{edition.organizationName}</b></h4>
                      <p className="mb-0">{edition.city} - {edition.state}</p>
                      <p className="mb-0">nº {edition.number}</p>
                      <p className="mb-0">{new Date(edition.dateEvent).toLocaleDateString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {loading && (
        <div className="text-center py-3">
          <ClipLoader color="#000" loading={loading} size={35} /> {/* Utilizando a animação ClipLoader */}
        </div>
      )} 
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="mb-0">© 2024 Rhymes Score. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
