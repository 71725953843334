import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactEcharts from 'echarts-for-react';
import { ClipLoader } from 'react-spinners';

function ProfileChart({ nickName }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://scoreboard-j6oz.onrender.com/editions/performance/${nickName}`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [nickName]); // Atualiza os dados sempre que o nickName mudar

  const generateChartOptions = () => {
    const legendData = data.map(entry => entry.organization);
    const seriesData = data.map(entry => ({ value: entry.score, name: entry.organization }));

    return {
      title: {
        text: `Desempenho ${nickName}`,
        subtext: 'Big Four Sao Paulo - SP - 2024',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Vitórias',
          type: 'pie',
          radius: '50%',
          data: seriesData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
  };

  return (
    <div>
      {loading ? (
        <div className="text-center py-3">
          <ClipLoader color="#000" loading={loading} size={35} />
        </div>
      ) : (
        <ReactEcharts option={generateChartOptions()} />
      )}
    </div>
  );
}

export default ProfileChart;
