import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function AboutUs() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav className="navbar navbar-dark bg-primary">
        <div className="container">
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="#">
            <i className="fa d-inline fa-lg fa-circle-o"></i>
            <b>Rhymes Score</b>
          </a>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/topbigfour">Top 5 Big Four - São Paulo SP</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">Sobre Nós</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-body">Sobre Nós</h1>
              <p>O serviço Rhymes Score apresenta resultados atualizados das Batalhas de Rima de todo o Brasil. Acompanhe seus Mcs favoritos aqui!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="mb-0">© 2024 Rhymes Score. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
